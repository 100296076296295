
  export default {
    name: 'CountUpCounter',
    components: {
      ICountUp: () => import('vue-countup-v2'),
    },
    props: {
      componentId: {
        type: String,
        required: true,
      },
      container: {
        type: Object,
        required: false,
      },
      options: {
        type: Object,
      },
    },
    data() {
      return {
        delay: 5000,
        countOptions: {
          duration: 3,
          useEasing: true,
          useGrouping: true,
          separator: ',',
          decimal: '.',
          prefix: '',
          suffix: '',
        },
      };
    },
    created() {
      if (this.options && this.options.delay) {
        this.delay = +this.options.delay;
      }
      if (this.options && this.options.duration) {
        this.countOptions.duration = +this.options.duration;
      }
    },
    computed: {
      content() {
        return this.container?.content || [];
      },
    },
    methods: {
      endValue(value) {
        return +value;
      },
      isTextType(item) {
        return item.value && item.valueType === 'text';
      },
      isCounterType(item) {
        return item.value && item.valueType === 'counter';
      },
    },
  };
